<template>
    <v-container v-if="gruppe" fluid>
        <v-row>
            <v-col cols="12" class="d-flex align-center justify-space-between">
                <h1 class="text-h4" style="flex-grow: 1">{{ gruppe.tittel }}</h1>
            </v-col>
            <v-col cols="12">
                <v-tabs v-model="tab" class="v-tabs--content">
                    <v-tab>Generelt</v-tab>
                    <v-tab>Medlemmer ({{ antallDeltakere }})</v-tab>
                    <v-tab>Møter</v-tab>
                    <v-tab v-if="hasFullAccessGriefGroup()">Kommentarer</v-tab>
                </v-tabs>
                <br />
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card flat style="max-width: 800px">
                            <v-card-text>
                                <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-text-field v-model="generelt.motetidspunkt" label="Møtetidspunkt"> </v-text-field>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-text-field v-model="generelt.lokaler" label="Lokaler"> </v-text-field>
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-text-field v-model="generelt.antallPlasser" label="Antall plasser"> </v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-textarea v-model="generelt.beskrivelse" label="Beskrivelse" rows="2"></v-textarea>
                                                </v-col>
                                                <v-col cols="4" v-if="hasFullAccessGriefGroup()">
                                                    <date-field v-model="generelt.oppstart" label="Oppstart"></date-field>
                                                </v-col>
                                                <v-col cols="4" v-if="hasFullAccessGriefGroup()">
                                                    <date-field v-model="generelt.avsluttet" label="Avsluttet"></date-field
                                                ></v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-autocomplete
                                                        label="Gruppeledere"
                                                        v-model="ledere"
                                                        :items="alleFrivillge"
                                                        item-text="fulltNavn"
                                                        item-value="id"
                                                        chips
                                                        multiple
                                                        hide-details
                                                        :readonly="!hasFullAccessGriefGroup()"
                                                    ></v-autocomplete>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-form>
                                <br />
                            </v-card-text>
                            <v-card-actions>
                                <v-btn v-on:click="save" outlined>Lagre</v-btn>
                                <!--<v-btn v-on:click="remove" text>Slett</v-btn>-->
                            </v-card-actions>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <tab-participants v-model="gruppe"></tab-participants>
                    </v-tab-item>
                    <v-tab-item>
                        <tab-meetings v-model="gruppe"></tab-meetings>
                    </v-tab-item>
                    <v-tab-item v-if="hasFullAccessGriefGroup()">
                        <tab-comments v-model="gruppe" type="sorggruppe"></tab-comments>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DateField from '@/vendor/noop/components/DateField';
import TabParticipants from '@/pages/grief/TabParticipants';
import TabMeetings from '@/pages/grief/TabMeetings';
import TabComments from '@/components/TabComments.vue';

export default {
    name: 'GriefGroup',
    components: {
        DateField,
        TabParticipants,
        TabMeetings,
        TabComments,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            valid: null,
            gruppe: null,

            tab: null,

            generelt: {
                motetidspunkt: '',
                lokaler: '',
                antallPlasser: '',
                beskrivelse: '',
                oppstart: null,
                avsluttet: null,
            },
            ledere: [],

            alleFrivillge: [],
        };
    },
    computed: {
        ...mapGetters('api', ['hasFullAccessGriefGroup']),
        antallDeltakere: function () {
            let participants = 0;
            if (this.gruppe && this.gruppe.deltakere) {
                for (const participant of this.gruppe.deltakere) {
                    if (!participant.fjernet) {
                        participants++;
                    }
                }
            }
            return participants;
        },
    },
    created: async function () {
        await this.update();
    },
    methods: {
        ...mapActions('api', ['request', 'formatGriefGroup', 'formatPerson']),
        ...mapActions(['snackbar']),

        /**
         * update
         */
        update: async function (gruppe) {
            if (!gruppe) {
                const response = await this.request({
                    method: 'get',
                    url: '/sorggruppe/' + this.id,
                });

                if (response && typeof response.id != 'undefined') {
                    gruppe = response;
                }
            }

            if (gruppe) {
                this.gruppe = await this.formatGriefGroup(gruppe);
                this.generelt.motetidspunkt = this.gruppe.motetidspunkt || '';
                this.generelt.lokaler = this.gruppe.lokaler || '';
                this.generelt.antallPlasser = this.gruppe.antallPlasser || '';
                this.generelt.beskrivelse = this.gruppe.beskrivelse || '';
                this.generelt.oppstart = this.gruppe.oppstart;
                this.generelt.avsluttet = this.gruppe.avsluttet;

                this.ledere = [];
                if (this.gruppe.ledere) {
                    for (const leder of this.gruppe.ledere) {
                        if (!leder.fjernet) {
                            this.ledere.push(leder.person.id);
                        }
                    }
                }

                this.deltakere = [];
                if (this.gruppe.deltakere) {
                    for (const person of this.gruppe.deltakere) {
                        person.rediger = false;
                        this.deltakere.push(await this.formatPerson(person));
                    }
                }
            }

            // frivillige
            let response = await this.request({
                method: 'get',
                url: '/person/frivillig/sorg',
            });

            if (response && typeof Array.isArray(response)) {
                this.alleFrivillge = [];
                for (const person of response) {
                    this.alleFrivillge.push(await this.formatPerson(person));
                }
            }
        },

        /**
         * save
         */
        save: async function () {
            if (!this.$refs.form.validate()) {
                console.log('TODO: error message');
            } else {
                let gruppe = null;

                // generelt
                if (
                    this.generelt.motetidspunkt != this.gruppe.motetidspunkt ||
                    this.generelt.lokaler != this.gruppe.lokaler ||
                    this.generelt.antallPlasser != this.gruppe.antallPlasser ||
                    this.generelt.beskrivelse != this.gruppe.beskrivelse ||
                    this.generelt.oppstart != this.gruppe.oppstart ||
                    this.generelt.avsluttet != this.gruppe.avsluttet
                ) {
                    const data = new FormData();
                    data.append('motetidspunkt', this.generelt.motetidspunkt);
                    data.append('lokaler', this.generelt.lokaler);
                    if (this.generelt.antallPlasser) {
                        data.append('antallPlasser', this.generelt.antallPlasser);
                    }
                    data.append('beskrivelse', this.generelt.beskrivelse);
                    if (this.generelt.oppstart) {
                        data.append('oppstart', this.generelt.oppstart.format('DD.MM.YYYY'));
                    }
                    if (this.generelt.avsluttet) {
                        data.append('avsluttet', this.generelt.avsluttet.format('DD.MM.YYYY'));
                    }

                    const response = await this.request({
                        method: 'post',
                        url: '/sorggruppe/' + this.gruppe.id,
                        data: data,
                    });

                    if (response && typeof response.id != 'undefined') {
                        gruppe = response;
                    }
                }

                // ledere
                if (this.hasFullAccessGriefGroup()) {
                    const add = [];
                    const remove = [];

                    // add
                    for (const person of this.ledere) {
                        add.push(person);
                        if (this.gruppe.ledere) {
                            for (let current of this.gruppe.ledere) {
                                if (!current.fjernet) {
                                    current = current.person;
                                    if (person == current.id) {
                                        add.pop();
                                        break;
                                    }
                                }
                            }
                        }
                    }

                    // remove
                    if (this.gruppe.ledere) {
                        for (let current of this.gruppe.ledere) {
                            if (!current.fjernet) {
                                current = current.person;
                                remove.push(current.id);
                                for (const person of this.ledere) {
                                    if (person == current.id) {
                                        remove.pop();
                                    }
                                }
                            }
                        }
                    }

                    if (add.length) {
                        const data = new FormData();
                        data.append('personId', add);

                        const response = await this.request({
                            method: 'post',
                            url: '/sorggruppe/' + this.gruppe.id + '/leder',
                            data,
                        });
                        if (response) {
                            gruppe = response;
                        }
                    }

                    if (remove.length) {
                        const data = new FormData();
                        data.append('personId', remove);

                        const response = await this.request({
                            method: 'delete',
                            url: '/sorggruppe/' + this.gruppe.id + '/leder',
                            data,
                        });
                        if (response) {
                            gruppe = response;
                        }
                    }

                    if (gruppe) {
                        await this.update(gruppe);
                    }
                }
                this.snackbar('Lagret');
            }
        },

        /**
         * remove
         */
        /*remove: async function () {
            const response = await this.request({
                method: 'delete',
                url: '/sorggruppe/' + this.gruppe.id,
            });

            if (response && typeof response.id != 'undefined') {
                this.$router.push({ name: 'sorg/grupper' });
            }
        },*/

        /**
         * saveLedere
         */
        saveLedere: async function () {
            console.log(this.gruppeledere);

            const add = [];
            const remove = [];

            // add
            for (const person of this.ledere) {
                add.push(person);
                if (this.gruppe.ledere) {
                    for (const current of this.gruppe.ledere) {
                        if (person == current.id) {
                            add.pop();
                        }
                    }
                }
            }

            // remove
            if (this.gruppe.ledere) {
                for (const current of this.gruppe.ledere) {
                    remove.push(current.id);
                    for (const person of this.ledere) {
                        if (person == current.id) {
                            remove.pop();
                        }
                    }
                }
            }

            if (add.length) {
                const data = new FormData();
                data.append('personId', add);

                const response = await this.request({
                    method: 'post',
                    url: '/sorggruppe/' + this.gruppe.id + '/leder',
                    data,
                });
                if (!response) {
                    console.log('error');
                }
            }

            if (remove.length) {
                const data = new FormData();
                data.append('personId', remove);

                const response = await this.request({
                    method: 'delete',
                    url: '/sorggruppe/' + this.gruppe.id + '/leder',
                    data,
                });
                if (!response) {
                    console.log('error');
                }
            }
        },
    },
};
</script>
